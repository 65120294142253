import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
	faArrowAltCircleDown,
	faVirus,
	faBus,
	faRoad,
	faArchway,
	faConstruction,
	faWater,
	faCity,
	faShovel,
	faUserHardHat,
	faMailbox,
	faBars,
	faTimes,
	faLongArrowLeft
} from "@fortawesome/pro-solid-svg-icons";
import {
	faLinkedin,
	faTwitter,
	faFacebookF,
	faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
library.add([
	faArrowAltCircleDown,
	faVirus,
	faBus,
	faRoad,
	faArchway,
	faConstruction,
	faWater,
	faCity,
	faShovel,
	faUserHardHat,
	faMailbox,
	faBars,
	faTimes,
	faLinkedin,
	faLinkedinIn,
	faTwitter,
	faFacebookF,
	faLongArrowLeft
]);
dom.watch();
import "../scss/styles.scss";

import "bootstrap";
import Swiper from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper/core";

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Thumbs]);
import "./pages/home.js";

$(document).ready(function () {
	// Hero swiper

	var heroSwiper = document.querySelector(".hero-top");
	if (heroSwiper) {
		var heroThumbs = new Swiper(".hero-thumbs", {
			slidesPerView: 3,
			freeMode: true,
			allowTouchMove: false
		});
		var heroTop = new Swiper(".hero-top", {
			thumbs: {
				swiper: heroThumbs
			}
		});
	}

	var gallerySwiper = document.querySelector(".gallery-top");

	if (gallerySwiper) {
		var galleryThumbs = new Swiper(".gallery-thumbs", {
			// Optional parameters
			slidesPerView: 4,
			freeMode: true,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				768: {
					slidesPerView: 6
				},
				992: {
					slidesPerView: 8
				}
			}
		});

		var galleryTop = new Swiper(".gallery-top", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			},
			pagination: {
				el: ".swiper-pagination",
				type: "fraction"
			},
			thumbs: {
				swiper: galleryThumbs
			}
		});
	}

	// Sets hero swiper height minus navbar height

	function heroSwiperHeight() {
		var navHeight = $(".navbar").height();
		var notificationHeight = $(".notification-bar").outerHeight();
		console.log(notificationHeight);
		var offsetHeight = navHeight + notificationHeight;
		$(".swiper.hero .hero-top .swiper-wrapper").css(
			"margin-top",
			-offsetHeight
		);
	}

	// Home swiper offset
	// This prevents the swiper text from going under the swiper controls

	function heroTextOffset() {
		var homeHeroOffsetHeight =
			$(".swiper.hero .swiper-container.hero-thumbs").height() +
			40 +
			"px";
		$(".swiper.hero .hero-top .swiper-text").css(
			"padding-bottom",
			homeHeroOffsetHeight
		);
	}

	$(window).resize(function () {
		heroSwiperHeight();
		heroTextOffset();
	});

	heroSwiperHeight();
	heroTextOffset();

	//

	//

	$(".navbar-toggler").click(function () {
		if ($(".navbar-toggler").hasClass("collapsed")) {
			$(".navbar").addClass("expanded");
		} else {
			$(".navbar").removeClass("expanded");
		}
	});

	// Captions

	$(".caption-enabled, .rte img").each(function () {
		var alt = $(this).attr("alt");
		if ($(this).attr("alt")) {
			$(this).after("<div class='alt'>" + alt + "</div>");
		}
	});

	// Video containers - this allows for dynamically scaled videos at a 16 by 9 aspect ratio

	$(".rte iframe").each(function () {
		$(this).wrap("<div class='video-container'></div>");
	});

	// Meet the team mobile nav toggle
	$(".toggle").click(function () {
		$(".team-nav").slideToggle();
		$(this).toggleClass("active");
	});

	// Meet the team menu toggle
	$("#meet-the-team-tabs .nav-link").click(function () {
		var currentTeam = $("#meet-the-team-tabs .nav-link.active").text();
		$(".meet-the-team .toggle h2").text(currentTeam);
	});

	// Featured projects heading on service detail page
	// Preventing "double projects" in the featured projects heading
	var featuredProjectsHeading = $("#featured-projects-heading");
	var featuredProjectSplit = featuredProjectsHeading.text().split(" ");
	if (
		featuredProjectsHeading
			.text()
			.toLowerCase()
			.includes("projects projects")
	) {
		featuredProjectSplit.pop();
		headingWithoutDuplicate = featuredProjectSplit.join(" ");
		featuredProjectsHeading.text(headingWithoutDuplicate);
	}

	var swiper = new Swiper(".swiper-home-services", {
		slidesPerView: 1,
		spaceBetween: 20,
		breakpoints: {
			574: {
				slidesPerView: 2
			},
			720: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			1140: {
				slidesPerView: 3,
				spaceBetween: 50
			}
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev"
		}
	});

	// News
	var url = window.location.href;
	if (url.toLowerCase().includes("articles")) {
		$("#Articles-tab").tab("show"); //("aria-selected", true)
	} else if (url.toLowerCase().includes("awards")) {
		$("#Awards-tab").tab("show"); //.attr("aria-selected", true)
	} else if (url.toLowerCase().includes("newsletter")) {
		$("#Newsletters-tab").tab("show"); //.attr("aria-selected",true)
	}

	// News load more

	var numItems = $(".more").length;

	var clickCount = 1;
	$("#loadMore").click(function () {
		if (clickCount >= numItems) {
			$("#loadMore").hide();
		}
		var sectionNumber = clickCount + 1;
		var id = "#" + sectionNumber;
		$(id).show();
		$(id).css("display", "block");

		clickCount++;
	});

	// Meet the team params

	if (document.querySelector(".meet-the-team")) {
		var params = new URL(document.location).searchParams;
		if (params != "") {
			var team = params.get("team");
			var name = params.get("name");
			var teamNavItem = document.querySelector(
				`.list-group-item.${team}`
			);
			var teamTab = document.querySelector(`.tab-pane.${team}`);
			teamNavItem.click();
			var accordion = teamTab.querySelector(`.${name}`);
			accordion.querySelector("button").click();
			setTimeout(function () {
				accordion.scrollIntoView({ behavior: "smooth" });
			}, 200);
		}
	}
});
